<template>
  <div class="invitation-container">
    <div style="padding: 35px; padding-top: 15px;">
      <transition name="tour-guide-fade">
        <div
          v-if="showTitles"
          class="py-5 text-2xl font-weight-bold"
        >
          {{ $t('how_call_listening') }}
        </div>
      </transition>

      <transition
        v-if="$store.state.eventData.languages.length > 1"
        name="tour-guide-fade"
      >
        <div
          v-if="showTitles"
          class="text-xl"
        >
          {{ $t('edit_call_in_all_languages') }}
        </div>
      </transition>
      <transition
        v-else
        name="tour-guide-fade"
      >
        <div
          v-if="showTitles"
          class="text-xl"
        >
          {{ $t('edit_details_in_call_template') }}
        </div>
      </transition>
    </div>

    <phone-call-preview :message="msg"></phone-call-preview>

  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import { mdiAlertCircleOutline } from '@mdi/js'
import PhoneCallPreview from '@/components/EventDetails/PhoneCallPreview.vue'
import { i18n } from '@/plugins/i18n/index'
import { useToast } from 'vue-toastification/composition'
import { useTourGuideTitleAnimation } from '../useTourGuideTitleAnimation'

export default {
  name: 'Call',

  components: {
    PhoneCallPreview,
  },

  props: {
    currentStep: {
      required: true,
    },
    stepIndex: {
      required: true,
    },
  },

  setup(props) {
    const { showTitles } = useTourGuideTitleAnimation(props)

    const toast = useToast()
    const showToast = msg => toast.error(i18n.t(msg))

    const msg = ref()

    const getCallMessages = () => {
      store
        .dispatch('getVoiceMessagesTemplate', { id: store.state.eventData.id })
        .then(response => {
          const [defaultMessage] = response.data.filter(el => el.lang === store.state.eventData.defaultLang)
          msg.value = defaultMessage
        })
        .catch(() => {
          showToast('errorManageEvents')
        })
    }
    getCallMessages()

    return {
      showTitles,
      msg,

      icons: {
        mdiAlertCircleOutline,
      },
    }
  },
}
</script>
